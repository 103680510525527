// eslint-disable-next-line import/no-cycle
import CrudService from '@/api/configs/crud.service';
import { Field } from '@/modules/field/types/Field';

class FieldApiService extends CrudService<Partial<Field>, Field> {
  protected ENDPOINT = 'fields';

  protected normalizeData(data:Field) {
    return data;
  }
}

const fieldApiService = new FieldApiService();

export default fieldApiService;
