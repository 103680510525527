import { of } from 'rxjs';
import useDataSource from '@/common/Autocomplete/useDataSource';
import { catchError } from 'rxjs/operators';
import { OrganizationTypeDS } from '@/dialogs/OrganizationDialog/types/Organization';

let organizations: OrganizationTypeDS[] = null;
export default function useOrganizationsAutocompleteDataSource() {
  const {
    get, dataSubject, ...anyData
  } = useDataSource<OrganizationTypeDS>('/user/organizations', (search: string) => {
    if (!organizations) {
      get({ params: { search } })
        .pipe(
          catchError(() => of([])),
        ).subscribe((response: OrganizationTypeDS[]) => {
          dataSubject.next(response);
          organizations = response;
        });
    } else {
      dataSubject.next(organizations as OrganizationTypeDS[]);
    }
  });

  return {
    get,
    ...anyData,
    dataSubject,
  };
}
