// element.style {
//   background-position: -1px -2279px;
// }
// .Fy0Xbe {
//   background: url(//ssl.gstatic.com/i18n/flags/48x32/nobevel/66bdb7a…/flags.png) no-repeat 0 0;
//     background-size: 24px 3876px;
//   width: 24px;
//   height: 16px;
//   overflow: hidden;
// }

const countries = [
  {
    name: 'Афганистан',
    code: '+93',
    iso: 'AF',
    mask: '##-###-####',
    index: 187,
  },
  {
    name: 'Албания',
    code: '+355',
    iso: 'AL',
    mask: '(###)###-###',
    index: 77,
  },
  {
    name: 'Алжир',
    code: '+213',
    iso: 'DZ',
    mask: '##-###-####',
    index: 40,
  },
  {
    name: 'Американское Самоа',
    code: '+1',
    iso: 'AS',
    mask: '(684)###-####',
    index: 121,
  },
  {
    name: 'Андорра',
    code: '+376',
    iso: 'AD',
    mask: '###-###',
    index: 45,
  },
  {
    name: 'Ангола',
    code: '+244',
    iso: 'AO',
    mask: '(###)###-###',
    index: 155,
  },
  {
    name: 'Ангилья',
    code: '+1',
    iso: 'AI',
    mask: '(264)###-####',
    index: 158,
  },
  {
    name: 'Антигуа и Барбуда',
    code: '+1',
    iso: 'AG',
    mask: '(268)###-####',
    index: 67,
  },
  {
    name: 'Аргентина',
    code: '+54',
    iso: 'AR',
    mask: '(###)###-####',
    index: 193,
  },
  {
    name: 'Армения',
    code: '+374',
    iso: 'AM',
    mask: '##-###-###',
    index: 12,
  },
  {
    name: 'Аруба',
    code: '+297',
    iso: 'AW',
    mask: '###-####',
    index: 60,
  },
  {
    name: 'о-в Вознесения',
    code: '+247',
    iso: 'AC',
    mask: '####',
    index: 5,
  },
  {
    name: 'Австралия',
    code: '+61',
    iso: 'AU',
    mask: '#-####-####',
    index: 134,
  },
  {
    name: 'Австрия',
    code: '+43',
    iso: 'AT',
    mask: '(###)###-####',
    index: 102,
  },
  {
    name: 'Азербайджан',
    code: '+994',
    iso: 'AZ',
    mask: '##-###-##-##',
    index: 94,
  },
  {
    name: 'Багамы',
    code: '+1',
    iso: 'BS',
    mask: '(242)###-####',
    index: 27,
  },
  {
    name: 'Бахрейн',
    code: '+973',
    iso: 'BH',
    mask: '####-####',
    index: 115,
  },
  {
    name: 'Бангладеш',
    code: '+880',
    iso: 'BD',
    mask: '1###-######',
    index: 139,
  },
  {
    name: 'Барбадос',
    code: '+1',
    iso: 'BB',
    mask: '(246)###-####',
    index: 122,
  },
  {
    name: 'Беларусь',
    code: '+375',
    iso: 'BY',
    mask: '(##)###-##-##',
    index: 83,
  },
  {
    name: 'Бельгия',
    code: '+32',
    iso: 'BE',
    mask: '(###)###-###',
    index: 0,
  },
  {
    name: 'Белиз',
    code: '+501',
    iso: 'BZ',
    mask: '###-####',
    index: 36,
  },
  {
    name: 'Бенин',
    code: '+229',
    iso: 'BJ',
    mask: '##-##-####',
    index: 99,
  },
  {
    name: 'Бермудские о-ва',
    code: '+1',
    iso: 'BM',
    mask: '(441)###-####',
    index: 152,
  },
  {
    name: 'Боливия',
    code: '+591',
    iso: 'BO',
    mask: '#-###-####',
    index: 128,
  },
  {
    name: 'Ботсвана',
    code: '+267',
    iso: 'BW',
    mask: '##-###-###',
    index: 219,
  },
  {
    name: 'Британская территория в Индийском океане',
    code: '+246',
    iso: 'IO',
    mask: '###-####',
    index: 5,
  },
  {
    name: 'Бруней',
    code: '+673',
    iso: 'BN',
    mask: '###-####',
    index: 131,
  },
  {
    name: 'Болгария',
    code: '+359',
    iso: 'BG',
    mask: '(###)###-###',
    index: 208,
  },
  {
    name: 'Буркина-Фасо',
    code: '+226',
    iso: 'BF',
    mask: '##-##-####',
    index: 56,
  },
  {
    name: 'Бурунди',
    code: '+257',
    iso: 'BI',
    mask: '##-##-####',
    index: 150,
  },
  {
    name: 'Камбоджа',
    code: '+855',
    iso: 'KH',
    mask: '##-###-###',
    index: 17,
  },
  {
    name: 'Камерун',
    code: '+237',
    iso: 'CM',
    mask: '####-####',
    index: 165,
  },
  {
    name: 'Канада',
    code: '+1',
    iso: 'CA',
    mask: '(###)###-####',
    index: 106,
  },
  {
    name: 'Кабо-Верде',
    code: '+238',
    iso: 'CV',
    mask: '(###)##-##',
    index: 214,
  },
  {
    name: 'о-ва Кайман',
    code: '+1',
    iso: 'KY',
    mask: '(345)###-####',
    index: 22,
  },
  {
    name: 'Центрально-Африканская Республика',
    code: '+236',
    iso: 'CF',
    mask: '##-##-####',
    index: 145,
  },
  {
    name: 'Чад',
    code: '+235',
    iso: 'TD',
    mask: '##-##-##-##',
    index: 62,
  },
  {
    name: 'Чили',
    code: '+56',
    iso: 'CL',
    mask: '#-####-####',
    index: 103,
  },
  {
    name: 'Колумбия',
    code: '+57',
    iso: 'CO',
    mask: '(###)###-####',
    index: 24,
  },
  {
    name: 'Коморы',
    code: '+269',
    iso: 'KM',
    mask: '##-#####',
    index: 110,
  },
  {
    name: 'Конго - Браззавиль',
    code: '+242',
    iso: 'CG',
    mask: '##-#####',
    index: 141,
  },
  {
    name: 'о-ва Кука',
    code: '+682',
    iso: 'CK',
    mask: '##-###',
    index: 183,
  },
  {
    name: 'Коста-Рика',
    code: '+506',
    iso: 'CR',
    mask: '####-####',
    index: 168,
  },
  {
    name: 'Хорватия',
    code: '+385',
    iso: 'HR',
    mask: '##-###-###',
    index: 69,
  },
  {
    name: 'Куба',
    code: '+53',
    iso: 'CU',
    mask: '#-###-####',
    index: 58,
  },
  {
    name: 'Кипр',
    code: '+357',
    iso: 'CY',
    mask: '##-###-###',
    index: 43,
  },
  {
    name: 'Чехия',
    code: '+420',
    iso: 'CZ',
    mask: '(###)###-###',
    index: 182,
  },
  {
    name: 'Конго - Киншаса',
    code: '+243',
    iso: 'CD',
    mask: '(###)###-###',
    index: 117,
  },
  {
    name: 'Дания',
    code: '+45',
    iso: 'DK',
    mask: '##-##-##-##',
    index: 107,
  },
  {
    name: 'Джибути',
    code: '+253',
    iso: 'DJ',
    mask: '##-##-##-##',
    index: 169,
  },
  {
    name: 'Доминика',
    code: '+1',
    iso: 'DM',
    mask: '(767)###-####',
    index: 197,
  },
  {
    name: 'Египет',
    code: '+20',
    iso: 'EG',
    mask: '(###)###-####',
    index: 178,
  },
  {
    name: 'Сальвадор',
    code: '+503',
    iso: 'SV',
    mask: '##-##-####',
    index: 127,
  },
  {
    name: 'Экваториальная Гвинея',
    code: '+240',
    iso: 'GQ',
    mask: '##-###-####',
    index: 116,
  },
  {
    name: 'Эритрея',
    code: '+291',
    iso: 'ER',
    mask: '#-###-###',
    index: 55,
  },
  {
    name: 'Эсватини',
    code: '+268',
    iso: 'SZ',
    mask: '##-##-####',
    index: 184,
  },
  {
    name: 'Эфиопия',
    code: '+251',
    iso: 'ET',
    mask: '##-###-####',
    index: 198,
  },
  {
    name: 'Фолклендские (Мальвинские) о-ва',
    code: '+500',
    iso: 'FK',
    mask: '#####',
    index: 224,
  },
  {
    name: 'Фарерские о-ва',
    code: '+298',
    iso: 'FO',
    mask: '###-###',
    index: 84,
  },
  {
    name: 'Фиджи',
    code: '+679',
    iso: 'FJ',
    mask: '##-#####',
    index: 147,
  },
  {
    name: 'Финляндия',
    code: '+358',
    iso: 'FI',
    mask: '(###)###-##-##',
    index: 151,
  },
  {
    name: 'Франция',
    code: '+33',
    iso: 'FR',
    mask: '(###)###-###',
    index: 19,
  },
  {
    name: 'Французская Гвиана',
    code: '+594',
    iso: 'GF',
    mask: '#####-####',
    index: 19,
  },
  {
    name: 'Французская Полинезия',
    code: '+689',
    iso: 'PF',
    mask: '##-##-##',
    index: 133,
  },
  {
    name: 'Габон',
    code: '+241',
    iso: 'GA',
    mask: '#-##-##-##',
    index: 68,
  },
  {
    name: 'Гамбия',
    code: '+220',
    iso: 'GM',
    mask: '(###)##-##',
    index: 48,
  },
  {
    name: 'Грузия',
    code: '+995',
    iso: 'GE',
    mask: '(###)###-###',
    index: 66,
  },
  {
    name: 'Гана',
    code: '+233',
    iso: 'GH',
    mask: '(###)###-###',
    index: 170,
  },
  {
    name: 'Гибралтар',
    code: '+350',
    iso: 'GI',
    mask: '###-#####',
    index: 20,
  },
  {
    name: 'Греция',
    code: '+30',
    iso: 'GR',
    mask: '(###)###-####',
    index: 11,
  },
  {
    name: 'Гренландия',
    code: '+299',
    iso: 'GL',
    mask: '##-##-##',
    index: 138,
  },
  {
    name: 'Гренада',
    code: '+1',
    iso: 'GD',
    mask: '(473)###-####',
    index: 195,
  },
  {
    name: 'Гваделупа',
    code: '+590',
    iso: 'GP',
    mask: '(###)###-###',
    index: 30,
  },
  {
    name: 'Гуам',
    code: '+1',
    iso: 'GU',
    mask: '(671)###-####',
    index: 192,
  },
  {
    name: 'Гватемала',
    code: '+502',
    iso: 'GT',
    mask: '#-###-####',
    index: 71,
  },
  {
    name: 'Гвинея',
    code: '+224',
    iso: 'GN',
    mask: '##-###-###',
    index: 207,
  },
  {
    name: 'Гвинея-Бисау',
    code: '+245',
    iso: 'GW',
    mask: '#-######',
    index: 153,
  },
  {
    name: 'Гайана',
    code: '+592',
    iso: 'GY',
    mask: '###-####',
    index: 61,
  },
  {
    name: 'Гаити',
    code: '+509',
    iso: 'HT',
    mask: '##-##-####',
    index: 23,
  },
  {
    name: 'Ватикан',
    code: '+39',
    iso: 'VA',
    mask: '06 698#####',
    index: 188,
  },
  {
    name: 'Гондурас',
    code: '+504',
    iso: 'HN',
    mask: '####-####',
    index: 174,
  },
  {
    name: 'Гонконг',
    code: '+852',
    iso: 'HK',
    mask: '####-####',
    index: 218,
  },
  {
    name: 'Венгрия',
    code: '+36',
    iso: 'HU',
    mask: '(###)###-###',
    index: 53,
  },
  {
    name: 'Исландия',
    code: '+354',
    iso: 'IS',
    mask: '###-####',
    index: 159,
  },
  {
    name: 'Индия',
    code: '+91',
    iso: 'IN',
    mask: '(####)###-###',
    index: 132,
  },
  {
    name: 'Иран',
    code: '+98',
    iso: 'IR',
    mask: '(###)###-####',
    index: 161,
  },
  {
    name: 'Ирак',
    code: '+964',
    iso: 'IQ',
    mask: '(###)###-####',
    index: 50,
  },
  {
    name: 'Ирландия',
    code: '+353',
    iso: 'IE',
    mask: '(###)###-###',
    index: 157,
  },
  {
    name: 'Италия',
    code: '+39',
    iso: 'IT',
    mask: '(###)####-###',
    index: 9,
  },
  {
    name: 'Кот-д’Ивуар',
    code: '+225',
    iso: 'CI',
    mask: '##-###-###',
    index: 129,
  },
  {
    name: 'Ямайка',
    code: '+1',
    iso: 'JM',
    mask: '(876)###-####',
    index: 135,
  },
  {
    name: 'Иордания',
    code: '+962',
    iso: 'JO',
    mask: '#-####-####',
    index: 112,
  },
  {
    name: 'Кения',
    code: '+254',
    iso: 'KE',
    mask: '###-######',
    index: 212,
  },
  {
    name: 'Кирибати',
    code: '+686',
    iso: 'KI',
    mask: '##-###',
    index: 28,
  },
  {
    name: 'Республика Корея',
    code: '+82',
    iso: 'KR',
    mask: '##-###-####',
    index: 181,
  },
  {
    name: 'Кувейт',
    code: '+965',
    iso: 'KW',
    mask: '####-####',
    index: 202,
  },
  {
    name: 'Киргизия',
    code: '+996',
    iso: 'KG',
    mask: '(###)###-###',
    index: 126,
  },
  {
    name: 'Латвия',
    code: '+371',
    iso: 'LV',
    mask: '##-###-###',
    index: 154,
  },
  {
    name: 'Лесото',
    code: '+266',
    iso: 'LS',
    mask: '#-###-####',
    index: 177,
  },
  {
    name: 'Либерия',
    code: '+231',
    iso: 'LR',
    mask: '##-###-###',
    index: 166,
  },
  {
    name: 'Лихтенштейн',
    code: '+423',
    iso: 'LI',
    mask: '(###)###-####',
    index: 75,
  },
  {
    name: 'Литва',
    code: '+370',
    iso: 'LT',
    mask: '(###)##-###',
    index: 85,
  },
  {
    name: 'Люксембург',
    code: '+352',
    iso: 'LU',
    mask: '(###)###-###',
    index: 113,
  },
  {
    name: 'Макао',
    code: '+853',
    iso: 'MO',
    mask: '####-####',
    index: 209,
  },
  {
    name: 'Мадагаскар',
    code: '+261',
    iso: 'MG',
    mask: '##-##-#####',
    index: 98,
  },
  {
    name: 'Мальдивы',
    code: '+960',
    iso: 'MV',
    mask: '###-####',
    index: 47,
  },
  {
    name: 'Мали',
    code: '+223',
    iso: 'ML',
    mask: '##-##-####',
    index: 204,
  },
  {
    name: 'Мальта',
    code: '+356',
    iso: 'MT',
    mask: '####-####',
    index: 120,
  },
  {
    name: 'Маршалловы о-ва',
    code: '+692',
    iso: 'MH',
    mask: '###-####',
    index: 86,
  },
  {
    name: 'Мартиника',
    code: '+596',
    iso: 'MQ',
    mask: '(###)##-##-##',
    index: 14,
  },
  {
    name: 'Мавритания',
    code: '+222',
    iso: 'MR',
    mask: '##-##-####',
    index: 18,
  },
  {
    name: 'Маврикий',
    code: '+230',
    iso: 'MU',
    mask: '###-####',
    index: 176,
  },
  {
    name: 'Федеративные Штаты Микронезии',
    code: '+691',
    iso: 'FM',
    mask: '###-####',
    index: 136,
  },
  {
    name: 'Молдова',
    code: '+373',
    iso: 'MD',
    mask: '####-####',
    index: 217,
  },
  {
    name: 'Монголия',
    code: '+976',
    iso: 'MN',
    mask: '##-##-####',
    index: 206,
  },
  {
    name: 'Черногория',
    code: '+382',
    iso: 'ME',
    mask: '##-###-###',
    index: 175,
  },
  {
    name: 'Монтсеррат',
    code: '+1',
    iso: 'MS',
    mask: '(664)###-####',
    index: 44,
  },
  {
    name: 'Марокко',
    code: '+212',
    iso: 'MA',
    mask: '##-####-###',
    index: 189,
  },
  {
    name: 'Мозамбик',
    code: '+258',
    iso: 'MZ',
    mask: '##-###-###',
    index: 49,
  },
  {
    name: 'Намибия',
    code: '+264',
    iso: 'NA',
    mask: '##-###-####',
    index: 149,
  },
  {
    name: 'Науру',
    code: '+674',
    iso: 'NR',
    mask: '###-####',
    index: 137,
  },
  {
    name: 'Непал',
    code: '+977',
    iso: 'NP',
    mask: '##-###-###',
    index: 7,
  },
  {
    name: 'Нидерланды',
    code: '+31',
    iso: 'NL',
    mask: '##-###-####',
    index: 111,
  },
  {
    name: 'Новая Каледония',
    code: '+687',
    iso: 'NC',
    mask: '##-####',
    index: 97,
  },
  {
    name: 'Никарагуа',
    code: '+505',
    iso: 'NI',
    mask: '####-####',
    index: 10,
  },
  {
    name: 'Нигер',
    code: '+227',
    iso: 'NE',
    mask: '##-##-####',
    index: 42,
  },
  {
    name: 'Ниуэ',
    code: '+683',
    iso: 'NU',
    mask: '####',
    index: 167,
  },
  {
    name: 'о-в Норфолк',
    code: '+672',
    iso: 'NF',
    mask: '3##-###',
    index: 15,
  },
  {
    name: 'Северная Македония',
    code: '+389',
    iso: 'MK',
    mask: '##-###-###',
    index: 104,
  },
  {
    name: 'Северные Марианские о-ва',
    code: '+1',
    iso: 'MP',
    mask: '(670)###-####',
    index: 54,
  },
  {
    name: 'Норвегия',
    code: '+47',
    iso: 'NO',
    mask: '(###)##-###',
    index: 64,
  },
  {
    name: 'Оман',
    code: '+968',
    iso: 'OM',
    mask: '##-###-###',
    index: 199,
  },
  {
    name: 'Пакистан',
    code: '+92',
    iso: 'PK',
    mask: '(###)###-####',
    index: 163,
  },
  {
    name: 'Палау',
    code: '+680',
    iso: 'PW',
    mask: '###-####',
    index: 16,
  },
  {
    name: 'Палестина',
    code: '+970',
    iso: 'PS',
    mask: '##-###-####',
    index: 91,
  },
  {
    name: 'Панама',
    code: '+507',
    iso: 'PA',
    mask: '###-####',
    index: 65,
  },
  {
    name: 'Папуа — Новая Гвинея',
    code: '+675',
    iso: 'PG',
    mask: '(###)##-###',
    index: 114,
  },
  {
    name: 'Парагвай',
    code: '+595',
    iso: 'PY',
    mask: '(###)###-###',
    index: 190,
  },
  {
    name: 'Перу',
    code: '+51',
    iso: 'PE',
    mask: '(###)###-###',
    index: 72,
  },
  {
    name: 'Филиппины',
    code: '+63',
    iso: 'PH',
    mask: '(###)###-####',
    index: 143,
  },
  {
    name: 'Польша',
    code: '+48',
    iso: 'PL',
    mask: '(###)###-###',
    index: 89,
  },
  {
    name: 'Португалия',
    code: '+351',
    iso: 'PT',
    mask: '##-###-####',
    index: 39,
  },
  {
    name: 'Катар',
    code: '+974',
    iso: 'QA',
    mask: '####-####',
    index: 34,
  },
  {
    name: 'Реюньон',
    code: '+262',
    iso: 'RE',
    mask: '#####-####',
    index: 19,
  },
  {
    name: 'Румыния',
    code: '+40',
    iso: 'RO',
    mask: '##-###-####',
    index: 52,
  },
  {
    name: 'Россия',
    code: '+7',
    iso: 'RU',
    mask: '(###)###-##-##',
    index: 51,
  },
  {
    name: 'Руанда',
    code: '+250',
    iso: 'RW',
    mask: '(###)###-###',
    index: 216,
  },
  {
    name: 'Сен-Бартелеми',
    code: '+590',
    iso: 'BL',
    mask: '###-##-##-##',
    index: 19,
  },
  {
    name: 'о-в Св. Елены',
    code: '+290',
    iso: 'SH',
    mask: '####',
    index: 37,
  },
  {
    name: 'Сент-Китс и Невис',
    code: '+1',
    iso: 'KN',
    mask: '(869)###-####',
    index: 6,
  },
  {
    name: 'Сент-Люсия',
    code: '+1',
    iso: 'LC',
    mask: '(758)###-####',
    index: 108,
  },
  {
    name: 'Сен-Мартен',
    code: '+590',
    iso: 'MF',
    mask: '(###)###-###',
    index: 5,
  },
  {
    name: 'Сен-Пьер и Микелон',
    code: '+508',
    iso: 'PM',
    mask: '##-####',
    index: 81,
  },
  {
    name: 'Сент-Винсент и Гренадины',
    code: '+1',
    iso: 'VC',
    mask: '(784)###-####',
    index: 211,
  },
  {
    name: 'Самоа',
    code: '+685',
    iso: 'WS',
    mask: '##-####',
    index: 186,
  },
  {
    name: 'Сан-Марино',
    code: '+378',
    iso: 'SM',
    mask: '####-######',
    index: 171,
  },
  {
    name: 'Сан-Томе и Принсипи',
    code: '+239',
    iso: 'ST',
    mask: '##-#####',
    index: 194,
  },
  {
    name: 'Сенегал',
    code: '+221',
    iso: 'SN',
    mask: '##-###-####',
    index: 172,
  },
  {
    name: 'Сербия',
    code: '+381',
    iso: 'RS',
    mask: '##-###-####',
    index: 200,
  },
  {
    name: 'Сейшельские о-ва',
    code: '+248',
    iso: 'SC',
    mask: '#-###-###',
    index: 78,
  },
  {
    name: 'Сьерра-Леоне',
    code: '+232',
    iso: 'SL',
    mask: '##-######',
    index: 57,
  },
  {
    name: 'Сингапур',
    code: '+65',
    iso: 'SG',
    mask: '####-####',
    index: 2,
  },
  {
    name: 'Синт-Мартен',
    code: '+1',
    iso: 'SX',
    mask: '(721)###-####',
    index: 225,
  },
  {
    name: 'Словакия',
    code: '+421',
    iso: 'SK',
    mask: '(###)###-###',
    index: 179,
  },
  {
    name: 'Словения',
    code: '+386',
    iso: 'SI',
    mask: '##-###-###',
    index: 93,
  },
  {
    name: 'Южно-Африканская Республика',
    code: '+27',
    iso: 'ZA',
    mask: '##-###-####',
    index: 191,
  },
  {
    name: 'Южный Судан',
    code: '+211',
    iso: 'SS',
    mask: '##-###-####',
    index: 222,
  },
  {
    name: 'Испания',
    code: '+34',
    iso: 'ES',
    mask: '(###)###-###',
    index: 87,
  },
  {
    name: 'Шри-Ланка',
    code: '+94',
    iso: 'LK',
    mask: '##-###-####',
    index: 213,
  },
  {
    name: 'Судан',
    code: '+249',
    iso: 'SD',
    mask: '##-###-####',
    index: 26,
  },
  {
    name: 'Швеция',
    code: '+46',
    iso: 'SE',
    mask: '##-###-####',
    index: 29,
  },
  {
    name: 'Швейцария',
    code: '+41',
    iso: 'CH',
    mask: '##-###-####',
    index: 101,
  },
  {
    name: 'Сирия',
    code: '+963',
    iso: 'SY',
    mask: '##-####-###',
    index: 144,
  },
  {
    name: 'Таджикистан',
    code: '+992',
    iso: 'TJ',
    mask: '##-###-####',
    index: 13,
  },
  {
    name: 'Танзания',
    code: '+255',
    iso: 'TZ',
    mask: '##-###-####',
    index: 185,
  },
  {
    name: 'Того',
    code: '+228',
    iso: 'TG',
    mask: '##-###-###',
    index: 46,
  },
  {
    name: 'Токелау',
    code: '+690',
    iso: 'TK',
    mask: '####',
    index: 223,
  },
  {
    name: 'Тонга',
    code: '+676',
    iso: 'TO',
    mask: '#####',
    index: 82,
  },
  {
    name: 'Тринидад и Тобаго',
    code: '+1',
    iso: 'TT',
    mask: '(868)###-####',
    index: 32,
  },
  {
    name: 'Тунис',
    code: '+216',
    iso: 'TN',
    mask: '##-###-###',
    index: 41,
  },
  {
    name: 'Турция',
    code: '+90',
    iso: 'TR',
    mask: '(###)###-####',
    index: 125,
  },
  {
    name: 'Туркменистан',
    code: '+993',
    iso: 'TM',
    mask: '#-###-####',
    index: 205,
  },
  {
    name: 'Тёркс и Кайкос',
    code: '+1',
    iso: 'TC',
    mask: '(249)###-###',
    index: 100,
  },
  {
    name: 'Уганда',
    code: '+256',
    iso: 'UG',
    mask: '(###)###-###',
    index: 88,
  },
  {
    name: 'Украина',
    code: '+380',
    iso: 'UA',
    mask: '(##)###-##-##',
    index: 160,
  },
  {
    name: 'Великобритания',
    code: '+44',
    iso: 'GB',
    mask: '##-####-####',
    index: 5,
  },
  {
    name: 'Соединенные Штаты',
    code: '+1',
    iso: 'US',
    mask: '(###)###-####',
    index: 4,
  },
  {
    name: 'Уругвай',
    code: '+598',
    iso: 'UY',
    mask: '#-###-##-##',
    index: 210,
  },
  {
    name: 'Узбекистан',
    code: '+998',
    iso: 'UZ',
    mask: '##-###-####',
    index: 76,
  },
  {
    name: 'Венесуэла',
    code: '+58',
    iso: 'VE',
    mask: '(###)###-####',
    index: 79,
  },
  {
    name: 'Виргинские о-ва (Великобритания)',
    code: '+1',
    iso: 'VG',
    mask: '(284)###-####',
    index: 109,
  },
  {
    name: 'Виргинские о-ва (США)',
    code: '+1',
    iso: 'VI',
    mask: '(340)###-####',
    index: 140,
  },
  {
    name: 'Уоллис и Футуна',
    code: '+681',
    iso: 'WF',
    mask: '##-####',
    index: 19,
  },
  {
    name: 'Замбия',
    code: '+260',
    iso: 'ZM',
    mask: '##-###-####',
    index: 124,
  },
  {
    name: 'Зимбабве',
    code: '+263',
    iso: 'ZW',
    mask: '#-######',
    index: 164,
  },
];

export default countries;
