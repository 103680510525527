import { Feature, Map as MapOl, View } from 'ol';
import BaseLayer from 'ol/layer/Base';
import TileLayer from 'ol/layer/Tile';
import Interaction from 'ol/interaction/Interaction';
import { never } from 'ol/events/condition';
import 'ol/ol.css';
import LayerGroup from 'ol/layer/Group';
import { defaults, Select } from 'ol/interaction';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Geometry from 'ol/geom/Geometry';
import { MapOptions } from 'ol/PluggableMap';
import XYZ from 'ol/source/XYZ';
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4';
import { fromLonLat, get } from 'ol/proj';
import Cluster from 'ol/source/Cluster';
import CircleStyle from 'ol/style/Circle';
import {
  Fill, Stroke, Style, Text,
} from 'ol/style';
import { TileWMS } from 'ol/source';

export enum MapTileLayers {
  GOOGLE= 'GOOGLE',
  YANDEX= 'YANDEX',
}

proj4.defs('EPSG:3395', '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs');
register(proj4);
get('EPSG:3395').setExtent(
  [-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244],
);

export default class Map {
  map: MapOl;

  activeMapTileLayer: MapTileLayers = MapTileLayers.GOOGLE;

  hasRosreestr = false;

  styleCache = {};

  private listTileLayers: {[key in MapTileLayers]: BaseLayer[]} = {
    [MapTileLayers.GOOGLE]: [new TileLayer({
      source: new XYZ({
        url: 'https://mt1.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
        attributions: 'Google карты',
        projection: 'EPSG:3857',
        crossOrigin: 'anonymous',
      }),
      zIndex: 1,
    })],
    [MapTileLayers.YANDEX]: [new TileLayer({
      source: new XYZ({
        url: 'https://core-sat.maps.yandex.net/tiles?l=sat&v=3.788.0&z={z}&x={x}&y={y}&scale=1&lang=ru_UA',
        attributions: 'Яндекс карты',
        projection: 'EPSG:3395',
        crossOrigin: 'anonymous',
      }),
      zIndex: 1,
    }),
    new TileLayer({
      source: new XYZ({
        url: 'https://core-renderer-tiles.maps.yandex.net/tiles?l=skl&v=21.06.16-0-b210520094930&z={z}&x={x}&y={y}&scale=1&lang=ru_UA',
        projection: 'EPSG:3395',
        crossOrigin: 'anonymous',
      }),
      zIndex: 1,
    })],
  };

  private rosreestrLayer = new TileLayer({
    source: new TileWMS({
      url: 'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreObjects/MapServer/export',
      params: {
        layers: 'show:30,27,24,23,22,17,8,0',
        dpi: 96,
        format: 'png32',
        f: 'image',
        transparent: true,
        bboxSR: 102100,
        imageSR: 102100,
        size: '1024,1024',
      },
      serverType: 'mapserver',
      crossOrigin: 'anonymous',
    }),
    zIndex: 1,
  });

  staticSource = new VectorSource({
    wrapX: false,
  });

  source = new VectorSource({
    wrapX: false,
  });

  pointSource = new VectorSource({
    wrapX: false,
  });

  pointAssaySource = new VectorSource({
    wrapX: false,
  });

  clusterFeaturesSource = new VectorSource({
    wrapX: false,
  });

  clusterSource = new Cluster({
    distance: 1,
    source: this.clusterFeaturesSource,
  });

  staticVector = new VectorLayer({
    source: this.staticSource,
    zIndex: 3,
  });

  vector = new VectorLayer({
    source: this.source,
    zIndex: 2,
  });

  pointVector = new VectorLayer({
    source: this.pointSource,
    zIndex: 4,
  });

  pointsAssayVector = new VectorLayer({
    source: this.pointAssaySource,
    zIndex: 4,
    minZoom: 14,
    maxZoom: 18,
  });

  lineSource = new VectorSource({
    wrapX: false,
  });

  lineVector = new VectorLayer({
    source: this.lineSource,
    zIndex: 3,
  });

  styles = (feature) => {
    const size = feature.get('features').length;

    const zoom = this.map.getView().getZoom();

    let style: Style[] = [];
    if (size === 1 || zoom === 18) {
      feature.get('features')
        .forEach((feat) => {
          if (feat.getStyle().length) {
            style.push(...feat.getStyle());
          } else {
            style.push(feat.getStyle());
          }
        });
    } else {
      style = this.styleCache[size];
    }

    if (!style) {
      style = [new Style({
        image: new CircleStyle({
          radius: 10,
          stroke: new Stroke({
            color: '#fff',
          }),
          fill: new Fill({
            color: '#3399CC',
          }),
        }),
        text: new Text({
          text: size.toString(),
          fill: new Fill({
            color: '#fff',
          }),
        }),
      })];
      this.styleCache[size] = style;
    }
    return style;
  };

  clastersVector = new VectorLayer({
    source: this.clusterSource,
    zIndex: 3,
    style: this.styles,
  });

  select: Select = new Select({
    toggleCondition: never,
    multi: false,
    style: null,
    hitTolerance: 2,
    filter: (feature) => feature.get('unselectable') !== true,
  });

  get selected() {
    return this.select.getFeatures();
  }

  constructor(target?: string, options?: MapOptions) {
    const activeTileLayer = localStorage.getItem('MapTileLayer');
    if (activeTileLayer && activeTileLayer in MapTileLayers) {
      this.activeMapTileLayer = MapTileLayers[activeTileLayer];
    } else {
      localStorage.setItem('MapTileLayer', this.activeMapTileLayer);
    }

    this.map = new MapOl({
      target,
      interactions: defaults({ doubleClickZoom: false }),
      controls: [],
      view: new View({
        center: fromLonLat([40.15, 43.83]),
        zoom: 8,
        maxZoom: 18,
      }),
      ...options,
    });

    this.addLayers([
      this.staticVector,
      this.vector,
      this.pointVector,
      this.pointsAssayVector,
      this.lineVector,
      this.clastersVector,
    ]);

    this.addLayers(this.listTileLayers[this.activeMapTileLayer]);

    if (target) {
      this.init();
    }
  }

  mount(el: HTMLElement) {
    setTimeout(() => {
      this.setTarget(el);
    }, 200);
    this.init();
  }

  changeMapLayer(newActiveMapTileLayer: MapTileLayers) {
    if (this.listTileLayers[this.activeMapTileLayer]) {
      this.listTileLayers[this.activeMapTileLayer].forEach((item) => {
        this.map.removeLayer(item);
      });
    }

    this.activeMapTileLayer = newActiveMapTileLayer;
    localStorage.setItem('MapTileLayer', this.activeMapTileLayer);
    this.addLayers(this.listTileLayers[this.activeMapTileLayer]);
  }

  private init() {
    this.select.setActive(false);
    this.addInteraction(this.select);
  }

  unmount() {
    this.map.setTarget(undefined);
    this.select.setActive(false);
    this.removeInteraction(this.select);
  }

  getView() {
    return this.map.getView();
  }

  changeCursor(cursor: string) {
    this.map.getTargetElement().style.cursor = cursor;
  }

  getSize() {
    return this.map.getSize();
  }

  setTarget(el: HTMLElement) {
    this.map.setTarget(el);
  }

  setLayerGroup(groupe: LayerGroup) {
    this.map.setLayerGroup(groupe);
  }

  addLayer(layer: BaseLayer) {
    this.map.addLayer(layer);
  }

  addLayers(layers: BaseLayer[]) {
    layers.forEach((layer) => this.addLayer(layer));
  }

  removeLayer(layer: BaseLayer) {
    this.map.removeLayer(layer);
  }

  addInteraction(interaction: Interaction) {
    this.map.addInteraction(interaction);
  }

  removeInteraction(interaction: Interaction) {
    this.map.removeInteraction(interaction);
  }

  addSelect(feature: Feature<Geometry>) {
    this.select.getFeatures().push(feature);
  }

  clearSelect() {
    this.select.getFeatures().clear();
  }

  setActiveFeature(activeFeature:Feature<Geometry>) {
    if (activeFeature !== this.selected.item(0)) {
      this.clearSelect();
      this.addSelect(activeFeature);
    }
  }

  setView(view: View) {
    this.map.setView(view);
  }

  setRosreestr(status: boolean) {
    this.hasRosreestr = status;
    if (status) {
      this.addLayer(this.rosreestrLayer);
    } else {
      this.removeLayer(this.rosreestrLayer);
    }
  }
}
