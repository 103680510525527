import { defineStore } from 'pinia';

export type State = {
  loading: boolean;
};

export const useGlobalStore = defineStore('global', {
  state: ():State => ({
    loading: false,
  }),
  actions: {
    setLoading(loading: boolean) {
      this.loading = loading;
    },
  },
});
