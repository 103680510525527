import './styles/quasar.scss';
// eslint-disable-next-line import/extensions
import iconSet from 'quasar/icon-set/mdi-v6.js';
// eslint-disable-next-line import/extensions
import lang from 'quasar/lang/ru.js';
import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';;
import '@quasar/extras/mdi-v6/mdi-v6.css';

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    extras: [
      'roboto-font',
    ],
  },
  plugins: { Notify, Loading, Dialog },
  lang,
  iconSet,
};
