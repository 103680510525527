export enum TypesInstrumentsMap {
  Satellite = 'Satellite',
  Assay = 'Assay',
  Scouting = 'Scouting',
  LayerMap = 'LayerMap'
}

export enum TypesStatusFields {
  NotAnalysis = 'notAnalysis',
  NotStarted = 'notStarted',
  InProcess = 'inProcess',
  Finished = 'finished'
}
