import { defineStore } from 'pinia';
import { SatelliteType, SettingsSatellites } from '@/models/SatelliteType';
import ImageLayer from 'ol/layer/Image';
import LayerGroup from 'ol/layer/Group';
// eslint-disable-next-line import/no-cycle
import SatelliteImage from '@/modules/field/data/SatelliteImage';
import Map from '@/modules/map/data/Map';
import MeteoFeature from '@/modules/map/features/MeteoFeature';
// eslint-disable-next-line import/no-cycle
import FieldFeature from '@/modules/map/features/FieldFeature';
import ScoutingTaskFeature from '@/modules/map/features/ScoutingTaskFeature';
import { IndicatorType } from '@/dialogs/AssayDialog/constants/optionsIndicators';
// eslint-disable-next-line import/no-cycle
import AreaSchemeFromImage from '@/dialogs/AssayDialog/types/AreaSchemeFromImage';
import ElementaryAreasScheme from '@/dialogs/AssayDialog/types/ElementaryAreasScheme';
import { TypesStatusFields } from '@/modules/map/components/SettingsInstruments/types';
import TypeSorting from '@/modules/field/components/list/SortingFields/sorting.type';
import { useFieldStore } from '@/stores/field-store';
import { Group } from '@/modules/field/types/Field';
import moment from 'moment/moment';

const sortingFields = (
  typeSorting: TypeSorting,
  fields: FieldFeature[],
  isAscending: boolean,
) => [...fields].sort((a, b) => {
  if (a.props[typeSorting] < b.props[typeSorting]) {
    return isAscending ? -1 : 1;
  } if (a.props[typeSorting] > b.props[typeSorting]) {
    return isAscending ? 1 : -1;
  }
  return 0;
});

export type State = {
  settingsSatellites: SettingsSatellites;
  satelliteLayer: ImageLayer<any> | LayerGroup;
  satelliteImage: SatelliteImage;
  itemsTimeList: SatelliteType[];
  changeSettingsTimeList: (settingsTimeList: SettingsSatellites) => void;
  map: Map,
  meteos: MeteoFeature[];
  fields: FieldFeature[];
  filteredFields: FieldFeature[];
  fieldsByKey: FieldFeature[];
  groups: Group[];
  filteredGroups: Group[];
  fieldFilterConfig: {
    search: string;
    groupsIds: number[];
    typeSorting: TypeSorting;
    culturesIds: number[];
    isAscending: boolean;
    isCollectionComplete: boolean;
    statusAnalysisField: {[key in TypesStatusFields]: boolean} & {globalStatus: boolean}
  };
  scoutingTasks: ScoutingTaskFeature[];
  showScoutingAll: boolean;
  settingsAssay: {
    isNeedUpdateAssayData: boolean,
    indicator: IndicatorType,
    layer: ImageLayer<any> | LayerGroup;
    areaSchemes: AreaSchemeFromImage[],
    areaScheme: AreaSchemeFromImage,
    elementaryAreasScheme: ElementaryAreasScheme
    applyToAll: boolean,
    // analyses
    analysesDate: string,
    loadingAreaSchemes: boolean,
    fieldIdAnalysisDate: number,
    layout: 'scheme' | 'average',
    interpolation: boolean,

  },
};

export const useMapStore = defineStore('map', {
  state: (): State => ({
    map: new Map(),
    settingsSatellites: {
      applyToAll: null,
      cloudiness: 20,
      imageDt: null,
      layerType: null,
      presentationType: '',
      fieldId: null,
    },
    fieldFilterConfig: {
      search: '',
      typeSorting: TypeSorting.NUMBER,
      groupsIds: [],
      culturesIds: [],
      isAscending: true,
      isCollectionComplete: false,
      statusAnalysisField: {
        globalStatus: true,
        [TypesStatusFields.Finished]: false,
        [TypesStatusFields.InProcess]: false,
        [TypesStatusFields.NotAnalysis]: false,
        [TypesStatusFields.NotStarted]: false,
      },
    },
    satelliteLayer: null,
    satelliteImage: null,
    settingsAssay: {
      isNeedUpdateAssayData: false,
      applyToAll: false,
      indicator: null,
      analysesDate: null,
      layer: null,
      areaScheme: null,
      areaSchemes: [],
      elementaryAreasScheme: null,
      loadingAreaSchemes: false,
      fieldIdAnalysisDate: null,
      layout: 'scheme',
      interpolation: false,

    },
    itemsTimeList: [],
    changeSettingsTimeList: () => null,
    meteos: [],
    fields: [],
    filteredFields: [],
    fieldsByKey: [],
    groups: [],
    filteredGroups: [],
    scoutingTasks: [],
    showScoutingAll: false,
  }),
  actions: {
    removeSatelliteLayer() {
      this.setSatelliteLayer(null);
      this.setSatelliteImage(null);
    },
    setItemsTimeList(itemsTimeList: SatelliteType[]) {
      this.itemsTimeList = itemsTimeList;
    },
    setChangeSettingsTimeList(payload) {
      this.setChangedSettingsTimeList(payload);
    },

    setMap(meteos: MeteoFeature[]) {
      this.meteos = meteos;
    },
    setSatelliteImage(satelliteImage: SatelliteImage) {
      this.satelliteImage = satelliteImage;
    },
    setShowScoutingAll(showScoutingAll: boolean) {
      this.showScoutingAll = showScoutingAll;
    },
    setSatelliteLayer(satelliteLayer: ImageLayer<any> | LayerGroup) {
      this.satelliteLayer = satelliteLayer;
    },
    setMeteos(meteos: MeteoFeature[]) {
      this.meteos = meteos;
      this.map.pointSource.clear();
      this.map.pointSource.addFeatures(meteos);
    },
    setFields(fields: FieldFeature[]) {
      this.fields = fields;
      const fieldsByKey = [];
      fields.forEach((item) => {
        fieldsByKey[item.props.id] = item;
      });

      this.fieldsByKey = fieldsByKey;
      this.updateFilteredFields();
    },
    setGroups(groups: Group[]) {
      this.groups = groups;
    },
    setScoutingTasks(scoutingTasks: ScoutingTaskFeature[]) {
      this.scoutingTasks = scoutingTasks;
    },
    setSettingsSatellite(settingsSatellites: SettingsSatellites) {
      this.settingsSatellites = settingsSatellites;
    },
    setStatusAnalysisField(key: TypesStatusFields, value: boolean) {
      this.fieldFilterConfig.statusAnalysisField[key] = value;
      const globalStatus = !Object.values(TypesStatusFields)
        .some((key) => this.fieldFilterConfig.statusAnalysisField[key]);

      this.fieldFilterConfig.statusAnalysisField.globalStatus = globalStatus;
      (<FieldFeature[]> this.map.source.getFeatures()).forEach((feature) => {
        feature.updateStyles();
      });
      this.updateFilteredFields();
    },

    setChangedSettingsTimeList(changeSettingsTimeList:
                                (settingsTimeList: SettingsSatellites) => void) {
      this.changeSettingsTimeList = changeSettingsTimeList;
    },
    updateFilteredFields() {
      const {
        search,
        culturesIds,
        groupsIds,
        isAscending,
        statusAnalysisField,
        typeSorting,
        isCollectionComplete,
      } = this.fieldFilterConfig;
      let newFilteredFields = this.fields;
      let newFilteredGroups = [{ name: 'Без группы', id: 0, fields: [] }, ...this.groups];
      if (search) {
        newFilteredFields = newFilteredFields
          .filter((item) => item.props.title.toLowerCase()
            .includes(search.toLowerCase()) || String(item.props.num) === search);
      } else {
        if (culturesIds?.length) {
          const keyCulturesIds = culturesIds.reduce((result, key) => {
            // eslint-disable-next-line no-param-reassign
            result[key] = true;
            return result;
          }, []);

          newFilteredFields = newFilteredFields
            .filter((item) => !!keyCulturesIds[item.props.activeCycle?.specie?.id]);
        }
        if (groupsIds?.length) {
          newFilteredGroups = newFilteredGroups
            .filter((item) => groupsIds.some((id) => id === item.id));
          const keyGroupsIds = newFilteredGroups.reduce((result, item) => {
            // eslint-disable-next-line no-param-reassign
            result[item.id] = true;
            return result;
          }, []);

          newFilteredFields = newFilteredFields
            .filter((item) => item.props.fieldGroups.some((id) => keyGroupsIds[id]));
        }

        if (isCollectionComplete) {
          // eslint-disable-next-line array-callback-return,consistent-return
          newFilteredFields = newFilteredFields.filter((field) => {
            const dateField = field.props.analyses.date;
            const validDate = dateField ? !moment(dateField).isAfter(moment().subtract(6, 'months')) : true;

            if (!validDate && !!field.props.analyses.isCollectionAnalyzesCompleted) {
              return field;
            }
          });
        }
        newFilteredFields = sortingFields(
          typeSorting,
          newFilteredFields as FieldFeature[],
          isAscending,
        );

        if (!statusAnalysisField.globalStatus) {
          newFilteredFields = newFilteredFields
            .filter((fieldFeature) => statusAnalysisField[fieldFeature.getStatusAssay()]);
        }
      }
      this.filteredFields = newFilteredFields;
      // @ts-ignore
      this.filteredGroups = newFilteredGroups;
      this.map.source.clear();
      this.map.source.addFeatures(newFilteredFields as FieldFeature[]);
      if (!useFieldStore().activeField) {
        this.map.getView().fit(this.map.source.getExtent(), {
          padding: [15, 15, 15, 15],
        });
      }
    },

    resetSettingsFilter() {
      Object.assign(
        this.fieldFilterConfig.statusAnalysisField,
        {
          globalStatus: true,
          notAnalysis: false,
          finished: false,
          inProcess: false,
          notStarted: false,
        },
      );
      this.fieldFilterConfig.typeSorting = TypeSorting.NUMBER;
      this.fieldFilterConfig.culturesIds = [];
      this.fieldFilterConfig.groupsIds = [];
      this.fieldFilterConfig.isAscending = true;
      this.updateFilteredFields();
    },

    setSortingAscending() {
      this.fieldFilterConfig.isAscending = !this.fieldFilterConfig.isAscending;
      this.updateFilteredFields();
    },
    clearAllData() {
      this.$reset();
    },
  },
});
