// eslint-disable-next-line import/no-cycle
import {
  useDistrictStore, useFieldStore, useMapStore, useMeteoStore, useOrganizationStore, useUserStore,
} from '@/stores';

export default function useClearAllData() {
  const userStore = useUserStore();
  const districtStore = useDistrictStore();
  const fieldStore = useFieldStore();
  const mapStore = useMapStore();
  const meteoStore = useMeteoStore();
  const organizationStore = useOrganizationStore();

  const clearAllData = () => {
    userStore.clearAllData();
    districtStore.clearDistricts();
    fieldStore.clearAllData();
    mapStore.clearAllData();
    meteoStore.clearAllData();
    organizationStore.clearAllData();
  };

  return { clearAllData };
}
