import { defineStore } from 'pinia';
import { Meteo } from '@/models/Meteo';

export type State = {
  activeMeteo: Meteo;
};

export const useMeteoStore = defineStore('meteo', {
  state: ():State => ({
    activeMeteo: null,
  }),
  actions: {
    setActiveMeteo(activeMeteo: Meteo) {
      this.activeMeteo = activeMeteo;
    },
    clearAllData() {
      this.$reset();
    },
  },
});
