import {
  BehaviorSubject, interval, merge, of,
} from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import {
  catchError, debounce, finalize, skip,
} from 'rxjs/operators';
import { onMounted, ref, Ref } from 'vue';
import httpService from '@/api/configs/http.service';

export type DataSource<T> = {
  loading: Ref<boolean>,
  getByID: (id: number) => Observable<T>,
  get: (options) => Observable<T[]>,
  reload: () => void,
  setFilters: (filter) => void,
  setSearch: (search) => void,
  data: Observable<T[]>,
  dataSubject: BehaviorSubject<T[]>
};

export default function useDataSource<T = any>(
  endpoint: string,
  getData : (search: string, filter?: any) => void,
): DataSource<T> {
  const loading = ref(false);

  const dataSubject: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([] as T[]);

  const data: Observable<T[]> = dataSubject.asObservable();

  const searchSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  const search: Observable<string> = searchSubject.asObservable();

  const filtersSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

  const filters: Observable<any> = filtersSubject.asObservable();

  const load = () => {
    getData(searchSubject.getValue(), filtersSubject.getValue());
  };

  onMounted(() => {
    merge(search, filters)
      .pipe(
        skip(4),
        debounce(() => interval(500)),
      ).subscribe(() => {
        load();
      });
  });

  const setSearch = (search) => {
    searchSubject.next(search);
  };

  const setFilters = (filter) => {
    filtersSubject.next(filter);
  };

  const reload = () => {
    load();
  };

  const get = (options): Observable<any> => {
    loading.value = true;
    return httpService.get(endpoint, options)
      .pipe(
        catchError(() => of({ data: [], meta: { total: 0 } })),
        finalize(() => {
          loading.value = false;
        }),
      );
  };

  const getByID = (id: number): Observable<T> => {
    loading.value = true;
    return httpService.get(`${endpoint}/${id}`)
      .pipe(
        // @ts-ignore
        catchError(() => of(null)),
        finalize(() => {
          loading.value = false;
        }),
      );
  };

  return {
    getByID,
    get,
    reload,
    setFilters,
    setSearch,
    loading,
    data,
    dataSubject,
  };
}
