<template>
  <div style="background-image: url('/images/authbkg.png')">
    <q-layout class="flex flex-center">
      <slot />
    </q-layout>
  </div>
</template>

<style scoped lang="scss">
  html, body, #app {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background: #d4d6d3;
    overflow: hidden;
  }
</style>
