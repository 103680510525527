import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import User from '@/models/User';
import { FormRegister } from '@/modules/auth/data/FormRegister';
// eslint-disable-next-line import/no-cycle
import httpService from '@/api/configs/http.service';
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import Me from '@/modules/auth/data/MeInterface';
import routeNames from '@/router/routeNames';
// eslint-disable-next-line import/no-cycle
import { useUserStore } from '@/stores';
// eslint-disable-next-line import/no-cycle
import useClearAllData from '@/hooks/useClearAllData';

export enum ENDPOINTS {
  AUTH = 'auth',
  REGISTER = 'register',
  USER = 'user',
  LOGOUT = 'logout',
  SET_ORG = 'auth/setorg',
}
class AuthApiService {
  login(login: string, password: string): Observable<any> {
    return httpService.post<User>(ENDPOINTS.AUTH, {
      login,
      password,
    }).pipe(
      catchError((e) => this.errorLogin(e.data)),
      map((data: User) => {
        this.setUserData(data);
        return data;
      }),
    );
  }

  getUser(): Observable<Me> {
    return httpService.get<Me>(ENDPOINTS.USER)
      .pipe(
        map<Me, Me>((data) => {
          this.setUserData(data);
          return data;
        }),
      );
  }

  setOrg(data): Observable<Me> {
    return httpService.post(ENDPOINTS.SET_ORG, data);
  }

  register(formRegister: FormRegister & {recaptchaToken: string}) {
    return httpService.post(ENDPOINTS.REGISTER, formRegister)
      .pipe(
        catchError((e) => this.errorLogin(e.data)),
        switchMap(() => this.getUser()),
      );
  }

  logout() {
    httpService
      .post(ENDPOINTS.LOGOUT)
      .subscribe(() => {
        this.clearAllData();
        router.push({ name: routeNames.login });
      }, (e) => {
        throw e;
      });
  }

  private setUserData(user: User) {
    this.setUserToStore(user);
  }

  private clearAllData() {
    useClearAllData().clearAllData();
  }

  private errorLogin(e): Observable<undefined> {
    this.clearAllData();
    return throwError(e);
  }

  private setUserToStore(user: User) {
    const userStore = useUserStore();
    userStore.setUser(user as Me);
  }
}

const authApiService = new AuthApiService();

export default authApiService;
