import { useRoute } from 'vue-router';
import { computed, reactive, toRefs } from 'vue';

export default function usePanel() {
  const route = useRoute();
  const panel = reactive({
    isNavPanel: computed(() => (route.matched.length && !!route.matched[route.matched.length - 1]
      .components?.navPanel)),
    isExpandedPanel: computed(() => route.matched.length
      && !!route.matched[route.matched.length - 1].components?.expandedPanel),
  });

  return toRefs(panel);
}
