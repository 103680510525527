import { defineStore } from 'pinia';
import { Field } from '@/modules/field/types/Field';

export type State = {
  activeField: Field;
};

export const useFieldStore = defineStore('field', {
  state: ():State => ({
    activeField: null,
  }),
  actions: {
    setActiveField(activeField) {
      this.activeField = activeField;
    },
    clearAllData() {
      this.$reset();
    },
  },
});
