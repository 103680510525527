export enum Positions {
    'right'='right',
    'left'='left',
    'top'='top',
    'bottom'='bottom'
}

export const positions:{[key in Positions]: {anchor: string, self: string}} = {
  [Positions.right]: {
    anchor: 'center right',
    self: 'center left',
  },
  [Positions.left]: {
    anchor: 'center left',
    self: 'center right',
  },
  [Positions.top]: {
    anchor: 'top middle',
    self: 'bottom middle',
  },
  [Positions.bottom]: {
    anchor: 'bottom middle',
    self: 'top middle',
  },

};
