import {
  createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw,
} from 'vue-router';
import Loading from 'quasar/src/plugins/Loading.js';;
// eslint-disable-next-line import/no-cycle
import restoreUserData from '@/modules/auth/guards/restoreUserData';
import Layout from '@/types/Layout';
// eslint-disable-next-line import/no-cycle
import checkAuthData from '@/modules/auth/guards/checkAuthData';
import routeNames from '@/router/routeNames';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/403',
    name: routeNames.error_403,
    component: () => import(/* webpackChunkName: "403" */'../views/errors/403.vue'),
    meta: {
      title: 'Ошибка 403',
      layout: Layout.default,
    },
  },
  {
    path: '/',
    name: routeNames.default,
    component: () => import(/* webpackChunkName: "default" */'../views/Default.vue'),
    meta: {
      title: 'КлеверFarmer',
      layout: Layout.default,
      auth: true,
    },
  },
  {
    path: '/districts',
    name: routeNames.districts,
    components: {
      default: () => import(/* webpackChunkName: "map" */'../views/Map.vue'),
      navPanel: () => import('../views/panels/Districts.vue'),
    },
    meta: {
      title: 'Районы',
      navPanelTitle: 'Районы',
      requiresRights: 'Districts',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/organizations',
    name: routeNames.organizations,
    components: {
      default: () => import(/* webpackChunkName: "map" */'../views/Map.vue'),
      navPanel: () => import('../views/panels/Organizations.vue'),
    },
    meta: {
      title: 'Организации',
      navPanelTitle: 'Организации',
      requiresRights: 'Organizations',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/fields',
    name: routeNames.fields,
    components: {
      default: () => import(/* webpackChunkName: "map" */'../views/Map.vue'),
      navPanel: () => import('../views/panels/ListFields.vue'),
    },
    meta: {
      title: 'Поля и посевы',
      navPanelTitle: 'Поля и посевы',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/agrochemical-analysis',
    name: routeNames.agrochemicalAnalysis,
    components: {
      default: () => import(/* webpackChunkName: "map" */'../views/Map.vue'),
      navPanel: () => import('../views/panels/AgrochemicalAnalysis.vue'),
    },
    meta: {
      title: 'АгроХимОбследование',
      navPanelTitle: 'АгроХимОбследование',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/fields/add-field',
    name: routeNames.addField,
    components: {
      default: () => import(/* webpackChunkName: "map" */'@/modules/field/components/AddOrUpdateField/Gis.vue'),
      navPanel: () => import('../views/panels/NewField.vue'),
    },
    meta: {
      title: 'Поля и посевы',
      navPanelTitle: 'Поля и посевы',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/fields/:id/update',
    name: routeNames.updateField,
    components: {
      default: () => import(/* webpackChunkName: "map" */'@/modules/field/components/AddOrUpdateField/Gis.vue'),
      navPanel: () => import('../views/panels/NewField.vue'),
    },
    meta: {
      title: 'Поля и посевы',
      navPanelTitle: 'Поля и посевы',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/fields/:id',
    name: routeNames.fieldsId,
    components: {
      default: () => import(/* webpackChunkName: "map" */'../views/Map.vue'),
      navPanel: () => import('../views/panels/Field.vue'),
    },
    meta: {
      title: 'Поля и посевы',
      navPanelTitle: 'Поля и посевы',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/directories',
    name: routeNames.directories,
    components: {
      default: () => import('../views/DirectoriesOperations.vue'),
    },
    meta: {
      title: 'Справочник работ',
      navPanelTitle: 'Справочник работ',
      layout: Layout.panel,
      auth: true,
    },
    children: [
      {
        path: '',
        redirect: { name: routeNames.directoriesOperations },
      },
      {
        path: 'operations',
        name: routeNames.directoriesOperations,
        component: () => import('../views/directories/Operations.vue'),
        meta: {
          title: 'Справочник работ',
          navPanelTitle: 'Справочник работ',
          layout: Layout.panel,
          auth: true,
        },
      },
      {
        path: 'machineries',
        name: routeNames.directoriesMachineries,
        component: () => import('../views/directories/Machineries.vue'),
        meta: {
          title: 'Машины и агрегаты',
          navPanelTitle: 'Машины и агрегаты',
          layout: Layout.panel,
          auth: true,
        },
      },
      {
        path: '*',
        redirect: { name: routeNames.error_404 },
      },
    ],
  },
  {
    path: '/fields/:id/split',
    name: routeNames.splitMap,
    components: {
      default: () => import('../views/SplitMap.vue'),
      navPanel: () => import('../views/panels/Field.vue'),
    },
    meta: {
      title: 'Поля и посевы',
      navPanelTitle: 'Поля и посевы',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/fields/:id/operations',
    name: routeNames.fieldOperations,
    components: {
      default: () => import(/* webpackChunkName: "map" */'../views/Map.vue'),
      navPanel: () => import('../views/panels/Field.vue'),
      expandedPanel: () => import('../views/panels/Operations.vue'),
    },
    meta: {
      title: 'Журнал работ',
      navPanelTitle: 'Поля и посевы',
      expandedPanelTitle: 'Журнал работ',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/fields/:id/history',
    name: routeNames.fieldHistory,
    components: {
      default: () => import(/* webpackChunkName: "map" */'../views/Map.vue'),
      navPanel: () => import('../views/panels/Field.vue'), // FieldPanel
      expandedPanel: () => import('../views/panels/SowingHistory.vue'), // HistoryPanel
    },
    meta: {
      title: 'История поля',
      navPanelTitle: 'Поля и посевы',
      expandedPanelTitle: 'История поля',
      layout: Layout.panel,
      auth: true,

    },
  },
  {
    path: '/fields/:id/satellite',
    name: routeNames.fieldSatellite,
    components: {
      default: () => import(/* webpackChunkName: "map" */'../views/Map.vue'),
      navPanel: () => import('../views/panels/Field.vue'),
      expandedPanel: () => import('../views/panels/Satellite.vue'), // SatellitePanel
    },
    meta: {
      title: 'Спутниковые данные',
      navPanelTitle: 'Поля и посевы',
      expandedPanelTitle: 'Спутниковые данные',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/fields/:id/scouting',
    name: routeNames.fieldScouting,
    components: {
      default: () => import(/* webpackChunkName: "map" */'../views/Map.vue'),
      navPanel: () => import('../views/panels/Field.vue'),
      expandedPanel: () => import('../views/panels/Scouting.vue'), // ScoutingPanel
    },
    meta: {
      title: 'Осмотры полей',
      navPanelTitle: 'Поля и посевы',
      expandedPanelTitle: 'Осмотры полей',
      layout: Layout.panel,
      auth: true,

    },
  },
  {
    path: '/fields/:id/scouting/:taskId',
    name: routeNames.scoutingTask,
    components: {
      default: () => import(/* webpackChunkName: "map" */'../views/Map.vue'),
      navPanel: () => import('../views/panels/Field.vue'),
      expandedPanel: () => import('../views/panels/ScoutingCard.vue'), // ScoutingCard
    },
    meta: {
      title: 'Осмотры полей',
      navPanelTitle: 'Поля и посевы',
      expandedPanelTitle: 'Осмотры полей',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/meteos',
    name: routeNames.meteos,
    components: {
      default: () => import(/* webpackChunkName: "map" */'../views/Map.vue'),
      navPanel: () => import('../views/panels/MeteoList.vue'),
    },
    meta: {
      title: 'Метеостанции',
      navPanelTitle: 'Метеостанции',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/meteo-health',
    name: routeNames.meteoHealth,
    components: {
      default: () => import('../views/reports/StationOperability.vue'),
    },
    meta: {
      title: 'Оценка работоспособности станций',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/organization-profile',
    name: routeNames.organizationProfile,
    components: {
      default: () => import('../views/OrganizationProfile.vue'),
    },
    meta: {
      title: 'Профиль организации',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/species-distribution',
    name: routeNames.speciesDistribution,
    components: {
      default: () => import('../views/reports/SpeciesDistribution.vue'),
    },
    meta: {
      title: 'Структура посевов',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/species-table',
    name: routeNames.speciesTable,
    components: {
      default: () => import('../views/reports/SpeciesTable.vue'),
    },
    meta: {
      title: 'Структура посевов/табличный вывод',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/fields-table',
    name: routeNames.fieldsTable,
    components: {
      default: () => import('../views/reports/FieldsTable.vue'),
    },
    meta: {
      title: 'Список полей/табличный вывод',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/fields-analysis-report',
    name: routeNames.fieldsAnalysisReport,
    components: {
      default: () => import('../views/reports/FieldsAnalysisReport.vue'),
    },
    meta: {
      title: 'Отчет анализов полей',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/meteos/:id',
    name: routeNames.meteosId,
    components: {
      default: () => import(/* webpackChunkName: "map" */'../views/Map.vue'),
      navPanel: () => import('../views/panels/Meteos.vue'),
    },
    meta: {
      title: 'Метеостанции',
      navPanelTitle: 'Метеостанции',
      layout: Layout.panel,
      auth: true,
    },
  },
  {
    path: '/login',
    name: routeNames.login,
    component: () => import('../views/Login.vue'),
    meta: {
      title: 'Вход',
      layout: Layout.default,
    },
    beforeEnter: checkAuthData,
  },
  {
    path: '/restore_password/:token',
    name: routeNames.restorePassword,
    component: () => import('../views/RestorePassword.vue'),
    meta: {
      title: 'Восстановление пароля',
      layout: Layout.default,
    },
  },
  {
    path: '/invite/:token',
    name: routeNames.invite,
    component: () => import('../views/Invite.vue'),
    meta: {
      title: 'Приглашение',
      layout: Layout.default,
      auth: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: routeNames.error_404,
    component: () => import(/* webpackChunkName: "404" */'../views/errors/404.vue'),
    meta: {
      title: 'Ошибка 404',
      layout: Layout.default,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  Loading.show();
  next();
});

router.beforeEach(restoreUserData);
router.afterEach(() => {
  setTimeout(
    () => {
      Loading.hide();
    },
    300,
  );
});

export default router;
