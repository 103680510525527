export enum Roles {
  ROOT = 'root', // farm / Суперпользователь
  ADMIN = 'admin', // farm / Администратор
  EXECUTIVE = 'executive', // farm / Руководитель
  CHIEF_AGRONOMIST = 'chief_agronomist', // farm / Главный агроном
  AGRONOMIST = 'agronomist', // farm / Агроном
  SUPPLY_MANAGER = 'supply_manager', // farm / Менеджер по снабжению
  CHIEF_MACHINE_OPERATOR = 'chief_machine_operator', // farm / Главный механизатор/Начальник гаража
  ECONOMIST = 'economist', // farm / Экономист/Бухгалтер
  EMPLOYEE = 'employee', // farm / Работник хозяйства
  SUPERVISOR = 'supervisor', // farm / Супервизор
  SCOUT = 'scout', // farm / Скаут
  ADM_ADMIN = 'adm_admin', // administrative /  Может просматривать подотчетные данные для любого хозяйства в зоне ответственности, создавать хозяйства и пользователей
  ADM_SPECIALIST = 'adm_specialist', // administrative / Может просматривать подотчетные данные для любого хозяйства в зоне ответственности
}

export enum OrganizationType {
  FARM = 'farm',
  ADMINISTRATIVE = 'administrative',
  HOLDING = 'holding',
}

interface Role {
  id: number;
  alias: Roles;
  title: string;
  organizationType: OrganizationType;
  description: string;
}

export default Role;
