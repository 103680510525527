import { Ability, AbilityBuilder } from '@casl/ability';
import Me from '@/modules/auth/data/MeInterface';
import { Roles } from '@/modules/auth/data/RoleInterface';
import { AdministrativeLevel } from '@/common/User/UserType';

const ability = new Ability();

const defineAbilityFor = (user: Me) => {
  const { rules, can } = new AbilityBuilder(Ability);

  const roles = user.roles.map((el) => el.alias);

  if (roles.includes(Roles.ROOT)) {
    can('show', 'ChangeOrganization');
    can('show', 'StationOperability');
    can('show', 'addSettingsAttributes');
    can('show', 'directories');
  }
  if (user.administrativeLevel === AdministrativeLevel.Region) {
    can('read', 'Districts');
    can('read', 'Organizations');
    can('show', 'RegionsChart');
  }

  if (user.administrativeLevel === AdministrativeLevel.District) {
    can('read', 'Organizations');
    can('show', 'DistrictsChart');
  }

  if (!user.administrativeLevel) {
    can('show', 'CreateField');
    can('show', 'ChangeField');
    can('show', 'fieldMeteoIcons');
    can('show', 'AddCycle');
    can('show', 'log');
    can('show', 'views');
    can('show', 'mapContextMenu');
    can('show', 'fieldAndMeteoData');
  }

  if (roles.includes(Roles.ADMIN)) {
    can('manage', 'Admin');
    can('manage', 'Passport');
    can('show', 'addSettingsAttributes');
    can('show', 'directories');
  }

  // if (roles.includes('manager')) {
  //   can('manage', 'Passport')
  // }

  // if (roles.includes('master')) {
  //   can('manage', 'Passport')
  // }

  return new Ability(rules);
};

export { ability, defineAbilityFor };
