import { defineStore } from 'pinia';
import OrganizationFeature from '@/modules/map/features/Organization';

export type State = {
  organizations: OrganizationFeature[];
};

export const useOrganizationStore = defineStore('organization', {
  state: (): State => ({
    organizations: [],
  }),
  actions: {
    setOrganization(organizations) {
      this.organizations = organizations;
    },
    clearAllData() {
      this.$reset();
    },
  },
});
