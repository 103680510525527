import {
  catchError, finalize, tap,
} from 'rxjs/operators';
import {
  BehaviorSubject, Subject, throwError,
  Observable,
} from 'rxjs';
import { AxiosError, AxiosRequestConfig } from 'axios';
// eslint-disable-next-line import/no-cycle
import httpService from '@/api/configs/http.service';

export type ErrorType = { [key: string]: string[] };

class BaseCrud<ResponseDataT = any> {
  protected loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public loading: Observable<boolean> = this.loadingSubject.asObservable();

  protected serverErrorsSubject: Subject<ErrorType> = new Subject<ErrorType>();

  public serverErrors: Observable<ErrorType> = this.serverErrorsSubject.asObservable();

  public clearErrors() {
    this.serverErrorsSubject.next({});
  }

  protected getData(
    url: string | AxiosRequestConfig,
    options?: AxiosRequestConfig,
  ): Observable<ResponseDataT[]> {
    this.loadingSubject.next(true);
    return httpService.get<ResponseDataT[]>(url as string, options).pipe(
      tap(this.notErrorHandler.bind(this)),
      catchError(this.errorHandler.bind(this)),
      finalize(() => this.loadingSubject.next(false)),
    );
  }

  protected getById(url: string, options?): Observable<ResponseDataT> {
    this.loadingSubject.next(true);
    return httpService.get<ResponseDataT>(url, options).pipe(
      tap(this.notErrorHandler.bind(this)),
      catchError(this.errorHandler.bind(this)),
      finalize(() => this.loadingSubject.next(false)),
    );
  }

  protected post(url: string, data: any) {
    this.loadingSubject.next(true);
    return httpService.post<ResponseDataT>(url, data).pipe(
      tap(this.notErrorHandler.bind(this)),
      catchError(this.errorHandler.bind(this)),
      finalize(() => {
        this.loadingSubject.next(false);
      }),
    );
  }

  protected put(url: string, data: any) {
    this.loadingSubject.next(true);
    return httpService.put(url, data).pipe(
      tap(this.notErrorHandler.bind(this)),
      catchError(this.errorHandler.bind(this)),
      finalize(() => {
        this.loadingSubject.next(false);
      }),
    );
  }

  protected delete(url) {
    this.loadingSubject.next(true);
    return httpService.delete(url).pipe(
      tap(this.notErrorHandler.bind(this)),
      catchError(this.errorHandler.bind(this)),
      finalize(() => {
        this.loadingSubject.next(false);
      }),
    );
  }

  protected errorHandler(e: AxiosError<{ errors: ErrorType }>) {
    if (e.response.status === 422) {
      this.serverErrorsSubject.next(e.response.data.errors);
      return throwError(e);
    }

    return throwError(e);
  }

  protected notErrorHandler() {
    this.serverErrorsSubject.next({});
  }
}

export default BaseCrud;
