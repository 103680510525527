import User from '@/models/User';
import CrudService from '@/api/configs/crud.service';

class UserApiService extends CrudService<Partial<User>, User> {
  protected ENDPOINT = 'users';

  protected normalizeData(data: User) {
    return data;
  }
}

const userApiService = new UserApiService();

export default userApiService;
