import { MultiPolygon, Polygon } from 'ol/geom';
import FeatureRoot from '@/modules/map/features/FeatureRoot';
import { cratePolygon } from '@/helpers/map';

export default abstract class BasePolygonFeature<T = any>
  extends FeatureRoot<T, Polygon | MultiPolygon> {
  setGeometryFeature(geometry) {
    const polygon = cratePolygon(geometry);
    super.setGeometry(polygon);
  }
}
