import { AxiosRequestConfig } from 'axios';
// eslint-disable-next-line import/no-cycle
import BaseCrud from '@/api/configs/base-crud';

abstract class CrudService<RequestDataT = any, ResponseDataT = any>
  extends BaseCrud<ResponseDataT> {
  protected abstract ENDPOINT: string;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get(options?: AxiosRequestConfig) {
    return super.getData(this.ENDPOINT, options);
  }

  getByID(id: number, options?:AxiosRequestConfig) {
    return super.getById(`${this.ENDPOINT}/${id}`, options);
  }

  add(data: RequestDataT) {
    return super.post(this.ENDPOINT, this.normalizeData(data));
  }

  edit(id: number, data: RequestDataT) {
    return super.put(`${this.ENDPOINT}/${id}`, this.normalizeData(data));
  }

  delete(id: number) {
    return super.delete(`${this.ENDPOINT}/${id}`);
  }

  protected abstract normalizeData(data: RequestDataT);
}

export default CrudService;
