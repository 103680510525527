import { defineStore } from 'pinia';
import DistrictFeature from '@/modules/map/features/DistrictFeature';

export type State = {
  districts: DistrictFeature[];
};

export const useDistrictStore = defineStore('district', ({
  state: (): State => ({
    districts: [],
  }),
  actions: {
    setDistricts(districts: DistrictFeature[]) {
      this.districts = districts;
    },
    clearDistricts() {
      this.$reset();
    },
  },
}));
