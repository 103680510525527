enum ElementType {
  Field = 'Field',
  District = 'District',
  Organization = 'Organization',
  Meteo = 'Meteo',
  ScoutingTask = 'ScoutingTask',
  ScoutingReport = 'ScoutingReport'
}

export default ElementType;
