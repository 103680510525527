import { defineStore } from 'pinia';
import Me from '@/modules/auth/data/MeInterface';
// eslint-disable-next-line import/no-cycle
import authApiService from '@/api/authApiService';
import { ability, defineAbilityFor } from '@/services/ability';

export type State = {
  user: Me
};

export const useUserStore = defineStore('user', {
  state: (): State => ({
    user: null,
  }),
  getters: {
    isUser: (state) => !!state.user?.id,
    isAuthorized: (state) => !!state?.user?.id,
    roles: (state) => state?.user?.roles?.map(({ alias }) => alias) || [],
  },
  actions: {
    async fetchUser() {
      return new Promise((resolve, reject) => {
        authApiService.getUser()
          .subscribe((userData) => {
            if (userData?.id) {
              ability.update(defineAbilityFor(userData).rules);
            }
            resolve(userData);
          }, (e) => {
            this.clearAllData();
            reject(e);
          });
      });
    },
    fetchLogout() {
      authApiService.logout();
    },

    async fetchOrganization({ params }) {
      return new Promise((resolve, reject) => {
        authApiService.setOrg(params).subscribe((data) => {
          window.location.href = '/';
          resolve(data);
        }, (e) => {
          reject(e);
        });
      });
    },

    setUser(user: Me) {
      this.$state.user = user;
    },

    clearAllData() {
      this.$reset();
    },
  },
});
