import { MultiPolygon, Polygon } from 'ol/geom';
import { fromLonLat, toLonLat } from 'ol/proj';
import {
  MultiPolygon as MultiPolygonTurf,
  Polygon as PolygonTurf,
} from '@turf/helpers/dist/js/lib/geojson';
import Geometry from 'ol/geom/Geometry';
import Feature from 'ol/Feature';
import { GeoJSON } from 'ol/format';
import {
  Feature as FeatureTurf, FeatureCollection, LineString, MultiLineString,
} from '@turf/helpers';
import { multiPolygonToLine, polygonToLine } from '@turf/polygon-to-line';

const formatGeoJSON = new GeoJSON();

export function cratePolygon(value: PolygonTurf | MultiPolygonTurf) {
  return value?.type === 'MultiPolygon'
    ? new MultiPolygon(value.coordinates
      .map((item) => new Polygon(item.map((poly) => poly.map((coord) => fromLonLat(coord))))))
    : new Polygon(value.coordinates
      .map((poly) => poly.map((coord) => fromLonLat(coord))));
}

export function getLineByPolygon(feature: any): FeatureTurf<LineString
    | MultiLineString, {[p: string]: any}>
  | FeatureCollection<LineString | MultiLineString, {[p: string]: any}> {
  if (feature.geometry.type === 'Polygon') {
    return polygonToLine(feature);
  } if (feature.geometry.type === 'MultiPolygon') {
    return multiPolygonToLine(feature);
  }
  return null;
}

export function getJsonPolygon(value: Geometry): PolygonTurf | MultiPolygonTurf {
  if (!value) {
    return null;
  }
  if (value.getType() === 'Polygon') {
    return {
      type: 'Polygon',
      coordinates: (value as Polygon).getCoordinates()
        .map((poly) => poly.map((coord) => toLonLat(coord))),
    };
  } if (value.getType() === 'MultiPolygon') {
    return {
      type: 'MultiPolygon',
      coordinates: (value as MultiPolygon).getCoordinates()
        .map((item) => item.map((poly) => poly.map((coord) => toLonLat(coord)))),
    };
  }
  return null;
}

export function formatGeoJSONWriteFeatures(features: Feature[]): FeatureCollection {
  return formatGeoJSON.writeFeaturesObject(
    features,
    { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' },
  ) as FeatureCollection;
}

export function formatGeoJSONWriteFeature(feature: Feature): FeatureTurf {
  return formatGeoJSON.writeFeatureObject(feature, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' }) as FeatureTurf;
}

export function formatGeoJSONReadFeatures(features: any): Feature[] {
  return formatGeoJSON.readFeatures(features, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
}
export function formatGeoJSONReadFeature(feature: any): Feature {
  return formatGeoJSON.readFeature(feature, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
}
