import axios from 'axios';

const openStreetMapSearchApiService = (searchText: string) => axios.get('https://nominatim.openstreetmap.org/search', {
  params:
      {
        q: searchText,
        format: 'json',
        addressdetails: 1,
        limit: '5',
        'accept-language': 'ru',
      },
});

export default openStreetMapSearchApiService;
