import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
// eslint-disable-next-line import/no-cycle
import { useUserStore } from '@/stores';

const checkAuthData = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const userStore = useUserStore();

  if (userStore.isAuthorized) {
    next('/');
  } else if (userStore.isUser === undefined) {
    userStore.fetchUser()
      .then(() => {
        if (userStore.isAuthorized) {
          next('/');
        } else {
          next();
        }
      })
      .catch(() => {
        next();
      });
  } else {
    next();
  }
};

export default checkAuthData;
